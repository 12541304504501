import { useState } from "react";
import "./TodoDetails.scss";
import edit_mark from "../../assets/imgs/pencil-square.svg";
import done_mark from "../../assets/imgs/check-circle-fill.svg";
import { TODOS_ACTIONS } from "../../enums/Actions";

export default function TodoDetails({
  toggleDetails,
  todo,
  todosDispatch,
}: any) {
  const [toggleTextarea, settoggleTextarea] = useState(todo.details === "");
  function handleDetailsChange(e: any) {
    todosDispatch({
      type: TODOS_ACTIONS.EDIT_DETAILS,
      payload: { id: todo.id, details: e.target.value },
    });
  }
  return (
    <div className={`details-card${toggleDetails ? " active" : ""}`}>
      <div className="details-div">
        <h3>Title: &nbsp;</h3>
        <b>{todo.name && todo.name[0].toUpperCase() + todo.name.slice(1)}</b>
      </div>
      <hr />
      <div className="details-div">
        <h3>Is task done? &nbsp;</h3>
        <b>{todo.isCompleted ? "Yes" : "Not yet"}</b>
      </div>
      <hr />
      <div className="details-div">
        <h3>Details: &nbsp;</h3>
        <button
          className={`btn btn-${toggleTextarea ? "add" : "edit"}`}
          onClick={() => {
            settoggleTextarea((prev: any) => !prev);
          }}
        >
          <img src={toggleTextarea ? done_mark : edit_mark} alt="" />
          &nbsp;
          <span>&nbsp; {toggleTextarea ? "Save" : "Edit"}</span>
        </button>
      </div>
      <p className={`details-paragraph`}>{todo.details}</p>
      {
        <textarea
          className={`input-textarea${toggleTextarea ? " active" : ""}`}
          placeholder="Type task details here..."
          value={todo.details}
          onChange={handleDetailsChange}
        />
      }
    </div>
  );
}
