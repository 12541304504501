import { useRef, useState } from "react";
import "./Todo.scss";
import { TODOS_ACTIONS } from "../../enums/Actions";
import TodoDetails from "../todo_details/TodoDetails";

export default function Todo({
  todo,
  todosDispatch,
  isAllMarked,
  setIsAllMarked,
}: any) {
  const [isEditMode, setIsEditMode] = useState(
    // {
    // name: todo.name,
    // isEditMode:
    false
    // ,
    // }
  );

  const [toggleDetails, setToggleDetails] = useState(false);

  const todoPrevName = useRef(todo.name);

  function handleToggle() {
    todosDispatch({
      type: TODOS_ACTIONS.COMPLETE_TASK,
      payload: {
        id: todo.id,
        isAllMarked: isAllMarked,
        setIsAllMarked: setIsAllMarked,
      },
    });
  }

  function handleDetails() {
    setToggleDetails((prev) => !prev);
  }

  function handleEdit() {
    setIsEditMode(true);
  }

  function handelEditSave() {
    if (todo.name.trim(" ") === "") return handleEditCancel();
    todoPrevName.current = todo.name;
    setIsEditMode(false);
  }

  function handleEditCancel() {
    setIsEditMode(false);
    todosDispatch({
      type: TODOS_ACTIONS.EDIT_TASK,
      payload: { ...todo, name: todoPrevName.current },
    });
  }

  function handleDelete() {
    todosDispatch({
      type: TODOS_ACTIONS.DELETE_TASK,
      payload: {
        id: todo.id,
        isAllMarked: isAllMarked,
        setIsAllMarked: setIsAllMarked,
      },
    });
  }
  return (
    <>
      {/* <hr /> */}
      <div className="todo">
        <input
          className="checkbox-input"
          type="checkbox"
          id={`check${todo.id}`}
          checked={todo.isCompleted}
          onChange={handleToggle}
        />
        {isEditMode ? (
          <>
            <div className="input-area">
              <input
                autoFocus={true}
                className="input-field"
                type="text"
                placeholder="Edit task name..."
                value={todo.name}
                maxLength={30}
                onChange={(e: any) => {
                  todosDispatch({
                    type: TODOS_ACTIONS.EDIT_TASK,
                    payload: { ...todo, name: e.target.value },
                  });
                  // setIsEditMode((prev: any) => ({
                  //   ...prev,
                  //   name: e.target.value,
                  // }));
                }}
              />
            </div>
            <div className="btn-container">
              <button
                type="button"
                className="btn btn-add"
                onClick={handelEditSave}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-delete"
                onClick={handleEditCancel}
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <label htmlFor={`check${todo.id}`}>
              <h2>
                {todo.name && todo.name[0].toUpperCase() + todo.name.slice(1)}
              </h2>
            </label>
            <div className="btn-container">
              <button
                type="button"
                className={`btn btn-${toggleDetails ? "warning" : "normal"}`}
                onClick={handleDetails}
              >
                Details
              </button>
              <button
                type="button"
                className="btn btn-edit"
                onClick={handleEdit}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-delete"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </>
        )}
      </div>
      <TodoDetails
        toggleDetails={toggleDetails}
        todo={todo}
        todosDispatch={todosDispatch}
        setToggleDetails={setToggleDetails}
      />
    </>
  );
}
