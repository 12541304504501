export class TodoModel {
  constructor(
    name: string = "",
    todo: TodoModel[] = [],
    details: string = "",
    isCompleted: boolean = false
  ) {
    this.id = generateId(todo);
    this.name = name;
    this.details = details;
    this.isCompleted = isCompleted;
  }

  id: number;
  name: string;
  details: string;
  isCompleted: boolean;
}

function generateId(todos: any[] = []) {
  return todos && todos.length > 0 ? todos[todos.length - 1].id + 1 : 1;
}
